import { connect } from 'react-redux';
import _ from 'lodash';
import { getContrastColor } from '../utils/utils';
import Header from '../components/event/Header';

const matchEvent = (state, eventId) => {
  if (typeof state.events[eventId] !== 'undefined' && state.events[eventId] !== null) {
    return state.events[eventId];
  }
  return '';
};

const mapStateToProps = (state, ownProps) => {
  const event = state.activeEvent;
  const settings = state.portfolioSettings.data;

  let photos;
  if (!settings.backgroundUrl && _.get(state, 'portfolioEvents.data')) {
    const previewEvent = state.portfolioEvents.data.find((event) => {
      return event.previewPhotos && event.previewPhotos.length;
    });
    if (previewEvent) {
      photos = previewEvent.previewPhotos.map((photo) => ({
        static: photo.thumbnailUrl,
      }));
    }
  }

  return {
    fullGalleryEnabled: true,
    title: settings.title || '',
    subtitle: settings.subtitle || '',
    date: '',
    theme: settings.theme,
    logo: settings.logoUrl,
    logoLink: settings.logoLink,
    background: settings.backgroundUrl || 'none',
    accentColor: settings.accentColor,
    contrastColor: getContrastColor(settings.accentColor),
    buttonFlag: settings.buttonFlag,
    buttonLink: settings.buttonLink,
    buttonText: settings.buttonText,
    isPortfolio: true,
    photos,
    handleSearch: ownProps.handleSearch,
    showSubtitle: true,
  };
};

const HeaderConnect = connect(mapStateToProps)(Header);

export default HeaderConnect;
